// font family
$main-font: "Lato", sans-serif;
// $second-font: "Inter", sans-serif;


// bg
$main-bg:#FFF;
// text

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #FFF;
$black:#171717;
$accent: #0072eb;
$second: #003193;
$error:#ff4242;

// modal
$modal-bgd: #2C2C2C;



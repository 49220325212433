// ========================================= common start
html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: $black;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
body.hidden {
  overflow: hidden;
}

section{
  padding: 40px 0;
}

// ------------------------------ navigation 

.navigation{

  display: flex;
  align-items: center;
  justify-content: space-between;
}

// ------------------------------  logo 
.logo{
  font-family: inherit;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: $black;
  transition: $transition;


  display: flex;
  align-items: center;
  gap: 8px;
    padding: 10px 10px;

  &:hover, &:focus {
      color: $accent;
    }
  
  &:active {
    color: inherit;
  }

}


// ------------------------------ links 

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  padding: 10px 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: inherit;


  transition: $transition;
  border-bottom: 1px solid transparent;
  
  &:hover,&:focus{
    color: $accent;
    border-bottom: 1px solid $accent;
  }
  &:active{
    color: $second;
    border-bottom: 1px solid transparent;
  }
}


// --------------------------------- btn
.btn {
  width: 100%;

  border-radius: 8px;
  padding: 18px 40px;
  border:none;
  background-color: $white;
  color: $black;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;

  // &-second{
  //       background-color: $white;
  //       border-radius: 0;
  //       color: $black;
  //       text-transform: uppercase;
  //       margin-top: 8px;
  //       max-width: 100%;
  // }
}

// ------------------------------ btn hover focus active 
.hfa {
  transition: $transition;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.0);

  &:hover,
  &:focus {
    background-color: $accent;
  }

  &:active {
    background-color: $second;
    -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  }

  // &-second{
  //     &:hover,
  //     &:focus {
  //       color: $white;
  //       background-color: $accent;
  //     }
  //     &:active {
  //       background-color: $white;
  //       color: $black;
  //       -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  //       -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  //       box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  //     }
  // }
}

// ------------------------------ headings 1  2  3 start

.heading1{
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: inherit;
}

.title{
  font-weight: 600;
    font-size: 32px;
    line-height: 1.2;
    color: inherit;
}


// ------------------------------ headings 1  2  3 end



.border-b{
  @include desktop{
  border-bottom: 2px solid $accent;
  }
  
}

//margin

.m-0{
  margin: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-16{
  margin-bottom: 16px;
}
.mb-18 {
  margin-bottom: 16px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-24{
  margin-bottom: 24px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-60 {
  margin-bottom: 60px;
}

//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:2px solid $error
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden -start
.header-nav,.upper, img {
  &.mobile-hidden{
    display: none;
    @include tablet{
      display: flex;
    }
  }
}
br, .header-nav,.upper, img{
  &.tablet-hidden {
    @include tablet{
      display: none;
    }
  }
}
.header-nav.tablet-hidden,.upper.tablet-hidden {
  @include desktop {
    display: flex;
  }
}
img, .btn-burger{
  &.desktop-hidden {

      @include desktop {
        display: none;
      }
    }
}

//  ------------------------------  mobile/tablet/desktop - hidden -end

.disc {
  list-style: disc;
  padding-left: 16px;
}
.decimal{
  list-style: decimal;
  padding-left: 16px;
}

.submit{
    min-height: calc(100vh - 320px);
      text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet{
      min-height: calc(100vh - 210px);
    }
      .link{
        margin: 0 auto;
        margin-top: 100px;
      }

      h1,h2{
        text-transform: uppercase;
      }
}

// ========================================= common end






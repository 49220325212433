.footer{
    padding: 24px 0;
    text-align: center;
    @include tablet{
        text-align: left;
    }

   & .navigation{
    max-width: 250px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    @include tablet{
        max-width: 100%;
    }
   }

   .content-wrapper{
    @include tablet{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
   }

   .logo{
    @include tablet{
        align-self: flex-start;
    }
   }

   &-list{
    @include tablet{
    max-width: 233px;
    }
   }

   .link{
    margin: 0 auto;
    @include tablet{
        margin: 0 0 0 auto;
    }
   }

   .rights{
    font-size: 14px;
        line-height: 150%;
    
   }
}
    


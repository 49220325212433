@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 40px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Lato", sans-serif;
  background-color: #FFF;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #171717;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

section {
  padding: 40px 0;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-family: inherit;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: #171717;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 10px;
}
.logo:hover, .logo:focus {
  color: #0072eb;
}
.logo:active {
  color: inherit;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 10px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
}
.link:hover, .link:focus {
  color: #0072eb;
  border-bottom: 1px solid #0072eb;
}
.link:active {
  color: #003193;
  border-bottom: 1px solid transparent;
}

.btn {
  width: 100%;
  border-radius: 8px;
  padding: 18px 40px;
  border: none;
  background-color: #FFF;
  color: #171717;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0);
}
.hfa:hover, .hfa:focus {
  background-color: #0072eb;
}
.hfa:active {
  background-color: #003193;
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}

.heading1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: inherit;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  color: inherit;
}

@media screen and (min-width: 1200px) {
  .border-b {
    border-bottom: 2px solid #0072eb;
  }
}

.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.just-validate-error-field.form-input {
  border: 2px solid #ff4242;
}

.just-validate-error-label {
  display: none;
}

.header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  br.tablet-hidden, .header-nav.tablet-hidden, .upper.tablet-hidden, img.tablet-hidden {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .header-nav.tablet-hidden, .upper.tablet-hidden {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  img.desktop-hidden, .btn-burger.desktop-hidden {
    display: none;
  }
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.decimal {
  list-style: decimal;
  padding-left: 16px;
}

.submit {
  min-height: calc(100vh - 320px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .submit {
    min-height: calc(100vh - 210px);
  }
}
.submit .link {
  margin: 0 auto;
  margin-top: 100px;
}
.submit h1, .submit h2 {
  text-transform: uppercase;
}

.header {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(23, 23, 23, 0.24);
  margin-bottom: 40px;
}

.footer {
  padding: 24px 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .footer {
    text-align: left;
  }
}
.footer .navigation {
  max-width: 250px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .footer .navigation {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .footer .content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .footer .logo {
    align-self: flex-start;
  }
}
@media screen and (min-width: 768px) {
  .footer-list {
    max-width: 233px;
  }
}
.footer .link {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .footer .link {
    margin: 0 0 0 auto;
  }
}
.footer .rights {
  font-size: 14px;
  line-height: 150%;
}

.hero {
  color: #FFF;
}
.hero.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/1.webp");
}
.hero .title {
  margin-bottom: 18px;
}

.choose .title {
  margin-bottom: 16px;
}

.contacts {
  padding-top: 80px;
  padding-bottom: 80px;
  color: #FFF;
  text-align: center;
}
.contacts.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.contacts .content-wrapper {
  max-width: 712px;
  margin: 0 auto;
}

.services {
  color: #FFF;
  border-radius: 16px;
}
.services.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/1.webp");
}
.services .text-wrapper {
  margin-bottom: 40px;
  max-width: 552px;
}
.services .title {
  margin-bottom: 16px;
}
.services .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .services .list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.services .list__item {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.18);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .services .list__item {
    max-width: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .services .list__item {
    max-width: 362px;
  }
}
.services .list__item-text-wrapper {
  padding: 16px;
}
.services .list__item-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
}
.services .list__item-link {
  border-top: 1px solid rgba(23, 23, 23, 0.1);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectors .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
@media screen and (min-width: 768px) {
  .sectors .content-wrapper {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .sectors .text-wrapper {
    max-width: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .sectors .text-wrapper {
    max-width: 552px;
  }
}
.sectors .title {
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 32px;
}
.sectors .subtitle {
  margin-bottom: 18px;
}
.sectors .disc {
  margin-bottom: 18px;
}
.sectors .image {
  width: 100%;
  max-width: 382px;
}

.contacts {
  color: #FFF;
  text-align: center;
  border-radius: 16px;
}
.contacts.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/1.webp");
}
.contacts .content-wrapper {
  max-width: 748px;
  margin: 0 auto;
}
.contacts .title {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 24px;
}
.contacts .subtitle {
  margin-bottom: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form {
    max-width: 357px;
  }
}
.form-label {
  width: 100%;
}
.form-input {
  width: 100%;
  border-radius: 8px;
  padding: 14px 38px;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.32);
  border: 2px solid transparent;
  font-size: inherit;
  line-height: inherit;
  color: #FFF;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: none;
}
.form-input:hover, .form-input:focus {
  border: 2px solid #0072eb;
}
.form-input::placeholder {
  color: #FFF;
}
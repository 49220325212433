.form{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 auto;
    width: 100%;
    @include tablet{
        max-width: 357px;
    }
    
    &-label{
        width: 100%;
    }

    &-input{
        width: 100%;
        border-radius: 8px;
        padding: 14px 38px;
        backdrop-filter: blur(4px);
        background-color: rgba(255, 255, 255, 0.32);;
        border: 2px solid transparent;
        font-size: inherit;
        line-height: inherit;
        color: $white;
        transition: $transition;
        outline: none;

        &:hover,&:focus{
            border: 2px solid $accent;
        }

        &::placeholder{
            color: $white;
        }
    
    }



}
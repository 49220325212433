.header{
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(23, 23, 23, 0.24);
    margin-bottom: 40px;



    // .burger-btn {
    // background: rgba(255, 255, 255, 0.08);
    //     backdrop-filter: blur(8px);
    //     padding: 12px;
    //     border-radius: 8px;
    //     margin: 0;
    //     border: none;
    //     color: inherit;
    //     cursor: pointer;
    //     transition: $transition;

    //     @include desktop {
    //         display: none;
    //     }

    //     & svg {
    //         stroke: currentColor;
    //     }

    //     &:hover,
    //     &:focus {
    //         color: $accent;
    //     }

    //     &:active {
    //         color: $second;
    //     }
    // }
}



.hero{
    color: $white;
    &.bg{
        background-repeat: no-repeat;
        background-position:center;
        background-size: cover;
        background-image: url('../img/1.webp');

    }

    .title{
        margin-bottom: 18px;
    }
}

.choose{
    .title{
        margin-bottom: 16px;
    }
}


.contacts{
    padding-top: 80px;
    padding-bottom: 80px;
    color: $white;
    text-align: center;
    &.bg{
        background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            // background-image: url('../img/5.webp');
        
    }

    .content-wrapper{
        max-width: 712px;
        margin: 0 auto;
    }

}

.services{
    color: $white;
    border-radius: 16px;
    &.bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('../img/1.webp');
    
        }

        .text-wrapper{
            margin-bottom: 40px;
            max-width: 552px;

        }
        .title {
            margin-bottom: 16px;
        }

        .list{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;

            @include tablet{
                flex-direction: row;
                flex-wrap: wrap;

            }

            &__item{
                backdrop-filter: blur(10px);
                background: rgba(255, 255, 255, 0.18);
                border-radius: 16px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include tablet{
                    max-width: 350px;
                }
                @include desktop{
                    max-width: 362px;
                }

                &-text-wrapper{
                    padding: 16px;
                }

                &-title{
                    font-weight: 600;
                        font-size: 18px;
                        margin-bottom: 16px;
                }
                &-link{
                    border-top: 1px solid rgba(23, 23, 23, 0.1);;
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                }
            }
        }
}


.sectors{
    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;

        @include tablet{
            flex-direction: row;

        }
    }

    .text-wrapper{

        @include tablet{
            max-width: 350px;
        }
        @include desktop{
        max-width: 552px;}
    }
    .title{
        margin-bottom: 18px;
        font-weight: 600;
            font-size: 32px;
    }
    .subtitle{
        margin-bottom: 18px;
    }
    .disc{
        margin-bottom: 18px
    }

    .image{
        width: 100%;
        max-width: 382px;
    }
}

.contacts{
    color: $white;
    text-align: center;
    border-radius: 16px;
    &.bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('../img/1.webp');

        }

    .content-wrapper{
        max-width: 748px;
        margin: 0 auto;
    }

    
    .title{
        font-weight: 600;
            font-size: 32px;
            margin-bottom: 24px;
    }
    .subtitle{
        margin-bottom: 40px;
    }
}